import { useDisclosure } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";

type Closer = (() => void) | undefined;

const SingletonDisclosureContext = React.createContext<
	| {
			singleCloser: Closer;
			register: Dispatch<SetStateAction<Closer>>;
	  }
	| undefined
>(undefined);

export const SingletonDisclosureContextProvider: ReactFC = ({ children }) => {
	const [singleCloser, register] = useState<Closer>(undefined);

	return (
		<SingletonDisclosureContext.Provider value={{ singleCloser, register }}>
			{children}
		</SingletonDisclosureContext.Provider>
	);
};

export const useSingletonDisclosure = () => {
	const ctx = useContext(SingletonDisclosureContext);
	const {
		onClose: thisOnClose,
		onOpen: thisOnOpen,
		isOpen,
		onToggle: _,
		...rest
	} = useDisclosure();

	const onOpen = () => {
		if (ctx?.singleCloser) {
			ctx.singleCloser();
		}

		if (ctx?.register) {
			ctx.register(() => thisOnClose);
		}

		thisOnOpen();
	};

	const onClose = () => {
		if (ctx?.register) {
			ctx.register(undefined);
			thisOnClose();
		}
	};

	const onToggle = isOpen ? onClose : onOpen;

	return { onOpen, onClose, isOpen, onToggle, ...rest };
};
