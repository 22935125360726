import { createContext, useContext } from "react";
import { MarketingQueryParams } from "./queryParams";

type TrackingContextType = {
	brandLabel?: string;
	queryParams?: MarketingQueryParams;
	article?: {
		slug?: string;
		_firstPublishedAt?: string;
		title?: string;
		company?: {
			wkn?: string;
			name?: string;
			price?: string;
			industry?: string;
		};
		categories?: Array<string>;
		tags?: Array<string>;
	};
	landingPage?: {
		title?: string;
		company?: {
			wkn?: string;
			name?: string;
			price?: string;
			industry?: string;
		};
	};
	articleImage?: {
		src?: string;
		desc?: string;
	};
};

const TrackingContext = createContext<TrackingContextType | undefined>(
	undefined,
);

export const useTrackingContext = () => useContext(TrackingContext);

export const TrackingContextProvider: ReactFC<{
	value: TrackingContextType;
}> = ({ value, children }) => {
	const previousValue = useTrackingContext();

	return (
		<TrackingContext.Provider value={{ ...previousValue, ...value }}>
			{children}
		</TrackingContext.Provider>
	);
};
