import { useRouter } from "next/router";
import { useMemo } from "react";

const allowList = [
	"utm_source",
	"utm_medium",
	"utm_name",
	"utm_term",
	"utm_content",
	"keyword",
	"placement",
	"device",
	"creative",
	"campaignid",
	"gclid",
] as const;

type FullMarketingQueryParams = Record<(typeof allowList)[number], string>;
export type MarketingQueryParams = Partial<
	FullMarketingQueryParams & { utm: Partial<FullMarketingQueryParams> }
>;

/**
 * Extracts all allow-listed query params and returns them as an object.
 * The `utm_` params are grouped in an object `utm: {}`
 */

export const useExtractMarketingQueryParams = () => {
	const { query } = useRouter();

	return useMemo(() => {
		const params = allowList.reduce<MarketingQueryParams>(
			(trackingObject, key) => {
				if (!(key in query)) {
					return trackingObject;
				}

				const value = query[key]?.toString();

				if (key.startsWith("utm_") && trackingObject.utm) {
					trackingObject.utm[key] = value;

					return trackingObject;
				}

				trackingObject[key] = value;

				return trackingObject;
			},
			{ utm: {} },
		);

		const foundSomething =
			Object.keys(params).length > 1 ||
			Object.keys(params.utm ?? {}).length > 0;

		return foundSomething ? params : undefined;
	}, [query]);
};
