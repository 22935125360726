import { useEffect } from "react";
import { useMeasure } from "react-use";

export const LayoutEffects: ReactFC = () => {
	const [setRef, rect] = useMeasure();

	useEffect(() => setRef(document.body), [setRef]);

	useEffect(() => {
		document.documentElement.style.setProperty(
			"--hundred-vw",
			`${rect.width}px`,
		);
		document.documentElement.style.setProperty(
			"--fifty-vw",
			`${rect.width / 2}px`,
		);
	}, [rect.width]);

	return null;
};
