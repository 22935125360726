import React from "react";
import type { AppProps } from "next/app";
import "../globalImports";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useExtractMarketingQueryParams } from "../lib/gtm/queryParams";
import { TrackingContextProvider } from "../lib/gtm/TrackingContext";
import { SingletonDisclosureContextProvider } from "../hooks/useSingletonDisclosure";
import { LayoutEffects } from "../components/LayoutEffects";

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => {
	const queryParams = useExtractMarketingQueryParams();

	return (
		<SingletonDisclosureContextProvider>
			<TrackingContextProvider value={{ queryParams }}>
				<LayoutEffects />
				<QueryClientProvider client={queryClient}>
					<Component {...pageProps} />
				</QueryClientProvider>
			</TrackingContextProvider>
		</SingletonDisclosureContextProvider>
	);
};

export default MyApp;
